import React, { useState } from "react";
import Select from "react-select";
import "../train/train.css";
import PageHead from "../common/pageHead/pageHead";
import BannerImg from "../../assets/train/img3.webp";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
const Train = () => {
  const gyms = [
    {
      id: 1,
      name: "The XL Gym, Sangli",
      address: "Kisan Chowk, Income Tax Road, Opposite Hotel Devgiri, Sangli, Maharashtra 416416",
      city: "Pune",
      lat: 16.85095444710734,
      lng: 74.58710750379973,
      gym_owner: "Deepali Sinhasane",
      contactNumber: "8686152777",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/xl+gym.jpg"

    },
    {
      id: 2,
      name: "H-One Fitness",
      address: "902, Road No. 47, Opp. CGR International School, Ayyappa Society, Mega Hills, Madhapur, Hyderabad, Telangana 500081",
      city: "Hyderabad",
      lat: 17.448870804523626,
      lng: 78.39253905776195,
      gym_owner: "Bharat",
      contactNumber: "9440334275",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/hone.jpg"
    },
    {
      id: 3,
      name: "Ninja X",
      address: "  C-36, Hauz Khas, Delhi 110016",
      city: "Delhi",
      lat: 28.54999151961986,
      lng: 77.20652359110879,
      gym_owner: "Nikhil Chauhan",
      contactNumber: "8368195274",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/ninjax.jpeg"
    },
    {
      id: 4,
      name: "CrossFit HITEC City",
      address: "Gate 2, East Wing, First Floor, ITPH Block A, Capitaland, Inorbit Mall Road, Madhapur, Hyderabad 500081",
      city: "Hyderabad",
      lat: 17.43545220615922,
      lng: 78.38324846880367,
      gym_owner: "Akshit",
      contactNumber: "9811176700",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/cfithitec1.jpg"
    },
    {
      id: 5,
      name: "Nexgen Fitness Studio",
      address: "6-14/23, Kistamma Enclave Road, Kistamma Enclave, Alwal, Secunderabad, Telangana 500015",
      city: "Hyderabad",
      lat: 17.499281673389582,
      lng: 78.49591538963925,
      gym_owner: "Naveen Reddy",
      contactNumber: "9966651328",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/nexgen.jpg"
    },
    {
      id: 6,
      name: "Fitlean Fitness",
      address: "V.V. Plaza, Plot No. 64/65, 3rd Floor, Gate No. 1, Opposite NSUT College, Shiv Park, Kakrola, New Delhi, Delhi 110078",
      city: "Delhi",
      lat: 28.610172994963502,
      lng: 77.03385068742578,
      gym_owner: "Chanda",
      contactNumber: "7827470522",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/fitlean.jpg"
    },
    {
      id: 7,
      name: "Atra Sports Science",
      address: "8-B, 3rd Main Road, Vijayanagar, Velachery, Chennai, Tamil Nadu 600042",
      city: "Chennai",
      lat: 12.972739241572286,
      lng: 80.26575169005119,
      gym_owner: "Rudresh",
      contactNumber: "9176756634",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/atra1.jpg"
    },
    {
      id: 8,
      name: "Squatz and Lungez",
      address: "No 60/54 Unitower, A6, 3rd Floor, Panampilly Nagar, Ernakulam Kerala 682036",
      city: "Kochi",
      lat: 9.954761850601324,
      lng: 76.29628703430147,
      gym_owner: "Vishnu",
      contactNumber: "8848435843",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/sqautzandlungez.jpg"
    },
    {
      id: 9,
      name: "Nucleus Fitness",
      address: "S No 57, Mayfield Estates, Behind Mio Pallazio Society, Off Dholepatil Farms Road, Tulaja Bhawani Nagar, Kharadi, Pune, Maharashtra 411014",
      city: "Pune",
      lat: 18.560102206753346,
      lng: 73.95076785786608,
      gym_owner: "Anne Thomas",
      contactNumber: "9373319078",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/nucleus.png"
    },
    {
      id: 10,
      name: "Orangetheory Fitness, Worli",
      address: "Unit No. 4, 3rd Floor, Trade View, Utopia City, Worli, Mumbai, Maharashtra 400013",
      city: "Mumbai",
      lat: 19.0057810941119,
      lng: 72.82711564594695,
      gym_owner: "Atharva",
      contactNumber: "9892661800",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/orangetheory.jpg"
    },
    {
      id: 11,
      name: "Orangetheory Fitness, Kemps Corner",
      address: "3rd Floor, Plot 139, B Wing, B - 3A to 3F, Residential & Shopping Complex, August Kranti Marg, Kemps Corner, Mumbai, Maharashtra 400036",
      city: "Mumbai",
      lat: 18.964092032278064,
      lng: 72.80628757902672,
      gym_owner: "Tejas",
      contactNumber: "9082912731",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/orangetheory.jpg"
    },
    {
      id: 12,
      name: "Orangetheory Fitness, Banjara Hills",
      address: " 3rd Floor, 8-2-547, The Hill Top 7, Road No. 7, Banjara Hills, Hyderabad, Telangana 500034",
      city: "Hyderabad",
      lat: 17.419747435837632,
      lng: 78.44069163653593,
      gym_owner: "Prateek",
      contactNumber: "8655702184",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/orangetheory.jpg"
    },
    {
      id: 13,
      name: "Fionis CrossFit",
      address: "Old Mahabalipuram Rd, Beside Gokulam Sabari OMR, Egattur, Navalur, Tamil Nadu 603103",
      city: "Chennai",
      lat: 12.84193436765369,
      lng: 80.22739728650795,
      gym_owner: "Anu",
      contactNumber: "9884051175",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/fioniscrossfit.jpg"
    },


  ];

  const uniqueCities = [...new Set(gyms.map((gym) => gym.city))];
  const cityOptions = uniqueCities.map((city) => ({
    value: city,
    label: city,
  }));

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);

  const filteredGyms = gyms.filter((gym) => {
    const matchesName = gym.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCity = selectedCity ? gym.city === selectedCity.value : true;
    return matchesName && matchesCity;
  });

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "2px 0",
    }),
    indicatorSeparator: (provided, state) => ({
      display: "none",
    }),
    control: (provided) => ({
      ...provided,
      color: "white",
      borderRadius: "6px",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#000",
      },
    }),
  };

  const handleCardClick = (gym) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${gym.lat},${gym.lng}`;
    window.open(googleMapsUrl, "_blank");
  };
  const truncateAddress = (address, maxLength = 500) => {
    if (!address) return "";
    return address.length > maxLength
      ? address.slice(0, maxLength) + "..."
      : address;
  };

  return (
    <div>
      <PageHead
        page_name="Train"
        page_head="Train"
        text_1="We know you’re eager to conquer Maruti Suzuki Arena Devils Circuit and thus, we’ve partnered with gyms across the country to help you to become the strongest version of yourself! With their guidance and tailored training programs, you will not only conquer the course but also transform your normal and emerge better than ever."
        media={BannerImg}
        media_type="image"
      />

      <div className="train_cards_main_div">
        <div className="train_cards_header_div">
          <p>Find your Gym</p>
          <div className="train_cards_header_underline"></div>
        </div>

        <div className="train_filters">
          <div className="train_search_div">
            <label htmlFor="search_name">Search</label>
            <input
              type="text"
              id="search_name"
              placeholder="Gym Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-bar"
            />
          </div>

          <div className="train_search_div">
            <label htmlFor="city">Select City</label>
            <Select
              id="city"
              options={cityOptions}
              value={selectedCity}
              onChange={setSelectedCity}
              placeholder="Select city"
              isClearable
              className="train_city_select"
              styles={customStyles}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="train_cards_div">
          <div className="train_cards_inner_div">
            {filteredGyms.map((gym) => (
              <div key={gym.id} className="train_card">
                <div className="train_card_img_div">
                  <img src={gym.img} alt="img" />
                </div>
                <div className="train_card_info_div">
                  <p className="train_card_name">{gym.name}</p>
                  <p className="train_card_contact">{gym.gym_owner}</p>
                  <p className="train_card_contact">
                    <PhoneIcon sx={{ marginLeft: "-5px", height: "20px" }} /> {gym.contactNumber}
                  </p>
                  <p className="train_card_address">
                    <LocationOnIcon sx={{ marginLeft: "-5px", height: "20px" }} />
                    {truncateAddress(gym.address)}{" "}
                    <span onClick={() => handleCardClick(gym)}>
                      {" "}
                      direction{" "}
                      <NearMeOutlinedIcon className="train_card_address_icon" />
                    </span>{" "}

                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Train;
