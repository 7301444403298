export const photos = {
  photoshead: "Go Down The Memory Lane",
  photosubtext:
    "Hello Devilslayers! You’ve written your DC story and we know you want to get your hands on those amazing photographs you clicked on the ground. Well, here they are. Find your city and use your selfie to download your images!",

  twothree: [
    {
      id: 1,
      city: "Indore",
      link: "https://www.snapd.me/search-photos/?eventId=02fc227b-c924-478f-8a33-412de8acabc8&id=20",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+1_11zon.webp",
    },
    {
      id: 2,
      city: "Mumbai",
      link: "https://snapd.me/search-photos/?eventId=37892ca1-4162-4ac4-a3d4-ce6a07989d5a&id=24",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+10_11zon.webp",
    },
    {
      id: 3,
      city: "Ahmedabad",
      link: "https://snapd.me/search-photos/?eventId=6359a139-6d82-43d1-b5bc-a47c0c0782a2&id=27",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+2_11zon.webp",
    },
    {
      id: 4,
      city: "Pune",
      link: "https://snapd.me/search-photos/?eventId=345913de-d9af-49b6-a3ac-6f0511c909d6&id=31",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+3_11zon.webp",
    },
    {
      id: 5,
      city: "Bengaluru",
      link: "https://snapd.me/search-photos/?eventId=bcd390ac-cae5-4468-996f-6e160685cf18&id=38",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+4_11zon.webp",
    },
    {
      id: 6,
      city: "Hyderabad",
      link: "https://snapd.me/search-photos/?eventId=16c7d48b-cfdc-452c-9803-24b769abb487&id=40",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+5_11zon.webp",
    },
    {
      id: 7,
      city: "Kochi",
      link: "https://snapd.me/search-photos/?eventId=fcb1a356-0c54-41ef-b544-2e032520cccc&id=43",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+6_11zon+(1).webp",
    },
    {
      id: 8,
      city: "Chennai",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+7_11zon+(1).webp",
    },
    {
      id: 9,
      city: "Mohali",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+8_11zon+(1).webp",
    },
    {
      id: 10,
      city: "Delhi NCR",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+9_11zon+(1).webp",
    },
  ],
};
